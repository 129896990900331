import JSZip from 'jszip'
import FileSaver from 'file-saver'

export const downloadZip = (data,filename) => {
    const zip = new JSZip()
    handleData(data,zip)
    zip.generateAsync({type: "blob"}).then(content => {
        FileSaver.saveAs(content, `${filename ?? 'file'}.zip`)
    })
}

function handleData(data,zip) {
    data.forEach(d => {
        if (!d.blob) {
            handleData(d.children,zip.folder(d.filename))
        } else {
            zip.file(d.filename,d.blob, {binary: true})
        }
    })
}
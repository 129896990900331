import { render, staticRenderFns } from "./studentbzr.vue?vue&type=template&id=eb44c2ae&scoped=true&"
import script from "./studentbzr.vue?vue&type=script&lang=js&"
export * from "./studentbzr.vue?vue&type=script&lang=js&"
import style0 from "./studentbzr.vue?vue&type=style&index=0&id=eb44c2ae&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.10.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb44c2ae",
  null
  
)

export default component.exports
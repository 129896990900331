<template>
  <div>
    <el-dialog title="信息采集" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
      width="800px" custom-class="cus_dialog" @close="closeDialog">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="信息采集" name="first"></el-tab-pane>
        <el-tab-pane v-if="!isCheck" label="生源信息表" name="second"></el-tab-pane>
        <el-tab-pane v-if="!isCheck" label="就业推荐表" name="third"></el-tab-pane>
        <el-tab-pane v-if="!isCheck" label="毕业去向表" name="fourth"></el-tab-pane>
      </el-tabs>

      <div v-if="activeName=='first'">
        <table class="dttable" v-if="StuInfo">
          <tr>
            <td class="tdheadersml">一寸照片</td>
            <td colspan="3">
              <el-image v-if="StuInfo.photo" style="width: 2.5cm; height: 3.5cm" :src="StuInfo.photo" fit="cover"
                :preview-src-list="[StuInfo.photo]"></el-image>
              <div v-else>未上传</div>
            </td>
          </tr>
          <tr>
            <td class="tdheadersml">姓名</td>
            <td style="width: 240px">{{ StuInfo.stu_name }}</td>
            <td class="tdheadersml">学号</td>
            <td>{{ StuInfo.stu_code }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">曾用名</td>
            <td>{{ StuInfo.old_name }}</td>
            <td class="tdheadersml">身份证号</td>
            <td>{{ StuInfo.idcard_encrypt }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">出生年月</td>
            <td>{{ StuInfo.birthday }}</td>
            <td class="tdheadersml">性别</td>
            <td>{{ StuInfo.gender == 1 ? "男" : "女" }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">籍贯</td>
            <td>{{ StuInfo.jiguan }}</td>
            <td class="tdheadersml">政治面貌</td>
            <td>{{ StuInfo.zzmm }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">手机号码</td>
            <td>{{ StuInfo.phone }}</td>
            <td class="tdheadersml">父亲电话</td>
            <td>{{ StuInfo.jhr1phone }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">母亲电话</td>
            <td>{{ StuInfo.jhr2phone }}</td>
            <td class="tdheadersml">所在地区</td>
            <td>{{ StuInfo.suozaidi }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">微信号</td>
            <td>{{ StuInfo.weixin }}</td>
            <td class="tdheadersml">家庭邮编</td>
            <td>{{ StuInfo.zip_code }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">电子邮箱</td>
            <td>{{ StuInfo.dzyx }}</td>
            <td class="tdheadersml">健康状况</td>
            <td>{{ StuInfo.health }}</td>
          </tr>
          <tr>

            <td class="tdheadersml">身高(CM)</td>
            <td>{{ StuInfo.height }}</td>
            <td class="tdheadersml">体重(KG)</td>
            <td>{{ StuInfo.weight }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">详细地址</td>
            <td colspan="3">{{ StuInfo.jtzz }}</td>
          </tr>
          <tr>
            <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">学籍信息</td>
          </tr>
          <tr>
            <td class="tdheadersml">所属学校</td>
            <td>{{ StuInfo.platform }}</td>
            <td class="tdheadersml">学校代码</td>
            <td>{{ StuInfo.platform_code }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">所属学院</td>
            <td>{{ StuInfo.xueyuan }}</td>
            <td class="tdheadersml">学校专业</td>
            <td>{{ StuInfo.class_info.zy_name }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">专业代码</td>
            <td>{{ StuInfo.class_info.zy_code }}</td>
            <td class="tdheadersml">专业方向</td>
            <td>{{ StuInfo.class_info.zy_type }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">学制</td>
            <td>{{ StuInfo.class_info.zy_xingzhi }}</td>
            <td class="tdheadersml">学期</td>
            <td>第二学期</td>
          </tr>
          <tr>
            <td class="tdheadersml">所在班级</td>
            <td>{{ StuInfo.class_name }}</td>
            <td class="tdheadersml">入学时间</td>
            <td>{{ StuInfo.ctime }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">毕业时间</td>
            <td>{{ StuInfo.graduate }}</td>
            <td class="tdheadersml">学习形式</td>
            <td>{{ StuInfo.learn }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">委培单位</td>
            <td>{{ StuInfo.commission }}</td>
            <td class="tdheadersml">生源地</td>
            <td>{{ StuInfo.place_of_origin }}</td>
          </tr>
          <tr>
            <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">在校表现</td>
          </tr>
          <tr>
            <td class="tdheadersml">专业主要课程</td>
            <td colspan="3">{{ StuInfo.course }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">担任班干部情况</td>
            <td colspan="3">{{ StuInfo.duties }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">奖惩情况</td>
            <td colspan="3">{{ StuInfo.awards }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">专业技能</td>
            <td colspan="3">
              <div v-for="(item, idx) in StuInfo.skill_json" :key="idx">{{ item }}</div>
            </td>
          </tr>
          <tr>
            <td class="tdheadersml">专业证书</td>
            <td colspan="3">
              <div v-for="(item, idx) in StuInfo.skill_img" :key="idx"
                style="display: inline-block; vertical-align: top; margin-left: 10px">
                <div>{{ item.name }}</div>

                <el-image v-if="item.file" style="width: 100px" :src="item.file" fit="cover"
                  :preview-src-list="[item.file]"></el-image>
              </div>
            </td>
          </tr>
          <tr>
            <td class="tdheadersml">技能大赛最高奖项</td>
            <td colspan="3">{{ StuInfo.skills_competition }}</td>
          </tr>

          <tr>
            <td class="tdheadersml">外语种类</td>
            <td>{{ StuInfo.efl_type }}</td>
            <td class="tdheadersml">熟练度</td>
            <td>{{ StuInfo.efl_proficiency }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">计算机水平</td>
            <td>{{ StuInfo.computer_level }}</td>
            <td class="tdheadersml"></td>
            <td></td>
          </tr>
          <tr>
            <td class="tdheadersml">特长爱好</td>
            <td colspan="3">{{ StuInfo.specialty }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">个人小结</td>
            <td colspan="3">{{ StuInfo.summary }}</td>
          </tr>

          <tr>
            <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">实习信息</td>
          </tr>

          <tr v-if="sxInfo">
            <td class="tdheadersml">实习去向</td>
            <td>
              <span v-for="(item, i) in sxqx" v-if="sxInfo.sxqx == item.param_value">{{
                item.param_desc
                }}</span>
            </td>
            <td class="tdheadersml">实习企业</td>
            <td>{{ sxInfo.firm_name }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">企业联系人</td>
            <td>{{ sxInfo.firm_content_name }}</td>
            <td class="tdheadersml">联系电话</td>
            <td>{{ sxInfo.firm_content_tel }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">部门</td>
            <td>{{ sxInfo.department }}</td>
            <td class="tdheadersml">岗位</td>
            <td>{{ sxInfo.job }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">指导老师</td>
            <td>{{ sxInfo.mentor_name }}</td>
            <td class="tdheadersml">指导老师电话</td>
            <td>{{ sxInfo.mentor_tel }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">实习薪资</td>
            <td>{{ sxInfo.xinzi }}</td>
            <td class="tdheadersml">薪资范围</td>
            <td>{{ salaryObj[sxInfo.salary] }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">实际上班地址</td>
            <td>{{ sxInfo.address }}</td>
            <td class="tdheadersml">安排方式</td>
            <td>{{ anpaiObj[sxInfo.anpai_type] }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">专业对口情况</td>
            <td>{{ sxInfo.is_ok == 1 ? '对口' : (sxInfo.is_ok == 0 ? '不对口' : '-') }}</td>
            <td class="tdheadersml">有无实习协议</td>
            <td>{{ sxInfo.is_contract == 1 ? '有' : (sxInfo.is_contract == 0 ? '没有' : '-') }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">是否购买保险</td>
            <td>{{ sxInfo.insured == 1 ? '有' : (sxInfo.insured == 0 ? '没有' : '-') }}</td>
            <td class="tdheadersml">有无公积金</td>
            <td>{{ sxInfo.is_housing_fund == 1 ? '有' : (sxInfo.is_housing_fund == 0 ? '没有' : '-') }}</td>
          </tr>
          <tr>
            <td class="tdheadersml">实习开始时间</td>
            <td>{{ sxInfo.start_time }}</td>
            <td class="tdheadersml">实习结束时间</td>
            <td>{{ sxInfo.end_time }}</td>
          </tr>
          <tr v-if="sxInfo && sxInfo.cover && sxInfo.cover.length>0">
            <td class="tdheadersml">资料附件</td>
            <td colspan="3">
              <div v-for="(item, idx) in sxInfo.cover" :key="idx"
                style="display: inline-block; vertical-align: top; margin-left: 10px">
                <el-image v-if="item.fileUrl" style="width: 100px" :src="item.fileUrl" fit="cover"
                  :preview-src-list="sxInfo.cover.map(e => { return e.fileUrl })"></el-image>
              </div>
            </td>
          </tr>

          <template v-if="$store.state.pmid != 214">
            <tr>
              <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">就业名册</td>
            </tr>

            <tr v-if="jiuye">
              <td class="tdheadersml">毕业去向</td>
              <td>{{ jiuye.emp_survey_name }}</td>

              <td class="tdheadersml">单位</td>
              <td>{{ jiuye.enter_name || jiuye.school_name }}</td>
            </tr>

            <tr v-if="jiuye">
              <td class="tdheadersml">证明文件</td>
              <td colspan="3">
                <div v-for="(item, idx) in jiuye.files" :key="idx"
                  style="display: inline-block; vertical-align: top; margin-left: 10px">
                  <el-image v-if="item.NEWURL" style="width: 100px" :src="item.NEWURL" fit="cover"
                    :preview-src-list="[item.NEWURL]"></el-image>
                </div>
              </td>
            </tr>

            <tr v-if="jiuyeMore">
              <td class="tdheadersml">单位性质</td>
              <td>{{ jiuye.enterprise_type_name }}</td>

              <td class="tdheadersml">是否对口</td>
              <td>{{ jiuye.counterpart == 1 ? "对口" : "不对口" }}</td>
            </tr>

            <tr v-if="jiuyeMore">
              <td class="tdheadersml">部门</td>
              <td>{{ jiuyeMore.dept_name }}</td>

              <td class="tdheadersml">岗位</td>
              <td>{{ jiuyeMore.job_name }}</td>
            </tr>

            <tr v-if="jiuyeMore2">
              <td class="tdheadersml">专业类别</td>
              <td>{{ jiuye.major_type }}</td>

              <td class="tdheadersml">企业类型</td>
              <td>{{ jiuyeMore2.companyOrgType }}</td>
            </tr>

            <tr v-if="jiuye">
              <td class="tdheadersml">产业类型</td>
              <td>{{ jiuye.industry_type_name }}</td>

              <td class="tdheadersml">地域</td>
              <td>{{ jiuye.area_id_name }}</td>
            </tr>

            <tr v-if="jiuye">
              <td class="tdheadersml">地点</td>
              <td>{{ jiuye.location_name }}</td>

              <td class="tdheadersml">渠道</td>
              <td>{{ jiuye.channel_name }}</td>
            </tr>
            <tr v-if="jiuye">
              <td class="tdheadersml">合同签订情况</td>
              <td>{{ jiuye.contract_name }}</td>

              <td class="tdheadersml">起薪情况</td>
              <td>{{ jiuye.salary_num }}</td>
            </tr>

            <tr v-if="jiuye">
              <td class="tdheadersml">社会保险情况</td>
              <td>{{ shebaoOptions[jiuye.social_security] }}</td>

              <td class="tdheadersml">资格证书情况</td>
              <td>{{ jiuye.certificate == 1 ? "取得" : "未取得" }}</td>
            </tr>
            <tr v-if="jiuye">
              <td class="tdheadersml">就业满意度</td>
              <td>
                <span v-if="jiuye.evaluation == 4">非常满意</span>
                <span v-if="jiuye.evaluation == 3">满意</span>
                <span v-if="jiuye.evaluation == 2">比较满意</span>
                <span v-if="jiuye.evaluation == 1">不满意</span>
                <span v-if="jiuye.evaluation == 0">无法评估</span>
              </td>
            </tr>
          </template>


          <template v-if="needSP">
            <tr>
              <td colspan="4" class="tdheadersml" style="background-color:#547df2a0;color:#fff;">审核状态</td>
            </tr>

            <tr>
              <td class="tdheadersml">审批状态</td>
              <td colspan="3">{{ StuInfo.sp_status == 0 ? '待审批':'' }}
                {{ StuInfo.sp_status == 1 ? '审批通过' : '' }}
                {{ StuInfo.sp_status == 2 ? '审批拒绝' : '' }}
              </td>
            </tr>
            <tr v-if="StuInfo.sp_status != 0">
              <td class="tdheadersml">审批意见</td>
              <td colspan="3">{{ StuInfo.sp_word || '-' }}</td>
            </tr>
          </template>
        </table>
      </div>
      <div v-if="activeName == 'first' && isCheck" slot="footer">
        <el-row>
          <el-col :span="18" class="sp_input">
            <el-input v-model="spword" type="textarea" :autosize="{ minRows: 2 }" :show-word-limit="true"
              :maxlength="200" style="width: 100%" size="small" placeholder="审批评语：审核拒绝时请填写拒绝原因"
              placeholder-style="color:#000"></el-input>
          </el-col>
          <el-col :span="6">
            <div class="flexEnd" style="width:100%">
              <el-button size="small" type="danger" @click="submitForm(2)">审批拒绝</el-button>
              <el-button size="small" type="success" @click="submitForm(1)">审批通过</el-button>
            </div>
          </el-col>
        </el-row>


      </div>

      <div v-if="(activeName != 'first') && !isCheck">
        <iframe :src="iframeUrl" style="width:100%;height:100vh;border:0;overflow:auto"></iframe>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "xxcj",
  props: ["stuid", "isCheck","needSP"],
  data() {
    return {
      activeName: "first",
      isShowModal: true,
      StuInfo: { class_info :{}},
      jiuye: {},
      jiuyeMore: {},
      jiuyeMore2: {},
      shebaoOptions: ["没有社保", "五险一金", "三险一金", "五险", "三险"],
      sxqx: [],
      anpaiObj: {
        0: '学校安排', 1: '自主选择', 2: '定向培养'
      },
      qyxzObj: {},
      salaryObj: {},
      sxInfo: { cover: []},
      code: '',
      url:"/91job/#",
      iframeUrl: "",
      spword:''
    };
  },
  mounted() {
    this.getConfig()
    this.getList();
    this.getParams();
  },
  methods: {
    getConfig() {
      this.$http.post("/api/sys_sch_config_list", {
        keyword: "91job_code",
        ktype: "ktype"
      }).then(res => {
        for (let item of res.data.data) {
          this.code = item.kvalue
        }
        if(!this.code){
          this.$message.error("未设置91job学校代码")
        }
      })
    },
    handleClick(){
        if(this.activeName=='second'){
          if(window.location.href.indexOf("localhost")>0){
            this.iframeUrl="//sx.qushixi.org.cn/91job/#/stusy?xxdm=" + this.code + "&student_codes=" + this.StuInfo.stu_code
          }else{
            this.iframeUrl=this.url+"/stusy?xxdm=" + this.code + "&student_codes=" + this.StuInfo.stu_code
          }
           
        }else if(this.activeName=='third'){
          if(window.location.href.indexOf("localhost")>0){
            this.iframeUrl="//sx.qushixi.org.cn/91job/#/stujytjb?xxdm=" + this.code + "&student_codes=" + this.StuInfo.stu_code
          }else{
            this.iframeUrl=this.url+"/stujytjb?xxdm=" + this.code + "&student_codes=" + this.StuInfo.stu_code
          }
           
        }else if(this.activeName=='fourth'){
          if(window.location.href.indexOf("localhost")>0){
            this.iframeUrl="//sx.qushixi.org.cn/91job/#/spinfo?xxdm=" + this.code + "&student_codes=" + this.StuInfo.stu_code
          }else{
            this.iframeUrl=this.url+"/spinfo?xxdm=" + this.code + "&student_codes=" + this.StuInfo.stu_code
          }
           
        }
    },
    getList() {
      this.$http
        .post("/api/t_sch_students_form", {
          stu_id: this.stuid,
        })
        .then((res) => {
          if (res.data.course) {
            res.data.course = JSON.parse(res.data.course).join("，");
          }
          if (res.data.duties) {
            res.data.duties = JSON.parse(res.data.duties).join("，");
          }
          if (res.data.awards) {
            try {
              res.data.awards = JSON.parse(res.data.awards).join("，");
            } catch (error) {
              res.data.awards = res.data.awards;
            }
          }
          if (res.data.skill_json) {
            res.data.skill_json = JSON.parse(res.data.skill_json);
          }
          if (res.data.skill_img) {
            res.data.skill_img = JSON.parse(res.data.skill_img);
          }
          if (res.data.skills_competition) {
            res.data.skills_competition = JSON.parse(res.data.skills_competition).join(
              "。"
            );
          }

          this.$http
            .post("/api/t_sch_students_relate", {
              stu_id: this.stuid,
            })
            .then((res2) => {
              for (let k in res2.data) {
                res.data[k] = res2.data[k];
              }
              res.data.class_info = res.data.class_info ? res.data.class_info : {}

              this.StuInfo = res.data;
            });

          this.$http
            .post("/api/jydc_info_by_stu_id", {
              stu_id: this.stuid,
            })
            .then((res2) => {
              if (res2.data.files) {
                res2.data.files = JSON.parse(res2.data.files);
              }
              this.jiuye = res2.data;
            });

          this.$http
            .post("/api/find_info_by_stu_id", {
              stu_id: this.stuid,
            })
            .then((res2) => {
              if (res2.data.files) {
                res2.data.files = JSON.parse(res2.data.files);
              }
              this.jiuyeMore = res2.data;

              this.$http
                .post("/api/tyc_search", {
                  enter_name: res2.data.enter_name,
                })
                .then((res3) => {
                  this.jiuyeMore2 = res3.data;
                });
            });

          this.$http
            .post("/api/t_sch_stu_enter_from", { id: res.data.stu_enter_id })
            .then((sx) => {
              let sxDate = sx.data
              sxDate.cover = sxDate.cover && this.isJSON(sxDate.cover) ? JSON.parse(sxDate.cover) : [];
              this.sxInfo = sxDate;
            });
        });

    },
    getParams() {
      this.$http
        .post("/api/sys_param_list", {
          param_type: "'ENTER_INFO','SALARY_RANGE','SHIXI_STATUS'",
          used: 0,
        })
        .then((res) => {
          let sxqx = [];
          let qyxzObj = {};
          let salaryObj = {};
          for (let item of res.data) {
            if (item.param_type == "SHIXI_STATUS") {
              sxqx.push(item);
            }
            if (item.param_type == "ENTER_INFO") {
              qyxzObj[item.param_value] = item.param_desc
            }
            if (item.param_type == "SALARY_RANGE") {
              salaryObj[item.param_value] = item.param_desc
            }
          }
          this.sxqx = sxqx;
          this.qyxzObj = qyxzObj;
          this.salaryObj = salaryObj;
        });
    },
    closeDialog() {
      this.$$parent(this, "showXxcj", false);
    },
    submitForm(st) {
      if (st==2&&!this.spword) {
        this.$message.error("请填写审批拒绝原因~")
        return
      }

      this.$confirm('确定审核?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        console.log(570, moment().format('YYYY-MM-DD'))
        this.$http.post("/api/stu_xxcj_sp", {
          id: this.stuid,
          sp_status: st,
          sp_date: moment().format('YYYY-MM-DD'),
          sp_word: this.spword
        }).then(res => {
          this.getList()
          this.$message({
            type: "success",
            message: "审核成功!"
          });
          this.$$parent(this, "getStudents");
          this.$$parent(this, "showXxcj", false);
        })

      }).catch(() => {

      });
    }
  },
};
</script>
